<template>
  <div class="col-sm-6 col-md-4 col-xl-3">
    <div class="my-4 text-center">
      <b-modal
        id="interventionModal"
        ref="interventionModal"
        size="xl"
        :title="'Intervention '"
        title-class="font-18"
        hide-footer
      >
        <form @submit.prevent="submit">
          <div class="row">
            <div class="col-8">
              <label for="formrow-inputCity">Liste des Produits *</label>
              <div v-for="(data, index) in phoneData" :key="data.id" class="inner mb-3 row">
                <div class="col-md-8 col-6">
                  <multiselect 
                    :close-on-select="true"
                    :select-label="''"
                    :deselect-label="''" 
                    :multiple="false" :clear-on-select="false" :preserve-search="true" v-model="interventionToMoForm.productsList[index]" :searchable="true" track-by="id" label="name" :options="products" placeholder="Selectionner les employés" :allow-empty="false">
                    <template slot="singleProduct" slot-scope="{ product }">{{ product.fullName }}</template>
                  </multiselect>
                </div>
                <div class="col-md-2 col-4">
                  <div class="d-grid">
                    <input
                      type="text"
                      class="form-control"
                      value=""
                      v-model="interventionToMoForm.qty[index]"
                    />
                  </div>
                </div>
                <div class="col-md-2 col-2">
                  <div class="d-grid">
                    <input
                      type="button"
                      class="btn btn-primary btn-block inner"
                      value="Delete"
                      @click="deleteProductsLine(index)"
                    />
                  </div>
                </div>
                </div>
                <input type="button" class="btn btn-success inner float-right" value="Ajouter Produit" @click="AddProductsLine"/>
            </div>
            <div class="col-4">
               <label for="formrow-inputCity">Heure fin d'intervnetion *</label>
              <input type="time" v-model="interventionToMoForm.timeEnd" class="form-control" name="" id=""/>
            </div>
          </div>

          <div>
            <button type="submit" class="btn btn-label btn-primary float-right ml-2" >
              <i class="far fa-check-square text-white label-icon"></i> Valide 
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import {
  fetchProductsList,
} from "@/api/common";
export default {
  props: {
      moId: {
          type: Number,
      },

      maintenanceOrderData : undefined,

      moExecutionType: {
          type: String,
      },
  },
  components: {Multiselect},
  data() {
    return {
      show: false,
      products: [],
      interventionToMoForm: {
        moId: "",
        requestUid: "",
        comments: "",
        productsList: [],
        timeEnd : "",
        qty : [],
      },

      executionResponsibleTypes: [
        {
          id: 'company',
          title: 'SONATRACH',
        },

        {
          id: 'supplier',
          title: 'Prestataire'
        }
      ],
      supId : null,
      inventionProductsList : [],
      fields: [{ id: 1 }],
      phoneData: [{ id: 1 }],
    };
  },
  mounted: function () { 
      this.fetchProductsList();
    // this.$root.$on('showAssignEmployeesModal', () => { // here you need to use the arrow function
     
    // })
  },
  watch : {
    maintenanceOrderData: function (val){
        if(val){
          this.supId                             = val;
          this.realoadSingleMaintenanceOrderData = false;
          this.inventionProductsList             = val.assignedEmployees;
          this.interventionToMoForm.comments  = val.employee_assignment_comments;
          // this.fetchProductsList();
        }
    }
  },
  methods: {
    AddProductsLine() {
      this.phoneData.push({ phone: "" });
    },
    /**
     * Delete the row from form
     */
    deleteProductsLine(index) {
      this.phoneData.splice(index, 1);
    },
    submit() {
        this.interventionToMoForm.moId = this.$props.moId;
        this.$http
        .post("/ops/maintenance_orders/interventionEmployeesToMo", this.interventionToMoForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success("l'operation a ete effectuée avec succes");
              this.$refs['interventionModal'].hide()
              this.$emit('realoadSingleMaintenanceOrderData',true)
              break;

            case 500:
              this.$toast.error(res.data.original.msg);
              
              break;
          }
        })
        .catch(() => {
          
        })
        .finally(() => {});
    },
   fetchProductsList(){
      var type = {"id" : "supplier_staff"};
      var supplier = {"id" : this.supId}; 
      this.$http
       .post("/ops/maintenance_orders/productsList")
        // .post("/ops/requests/maintenance/usersList",{type: type,supplier :supplier})
        .then((res) => {
          this.interventionToMoForm.productsList = "";  
          this.products = [];
          this.products = res.data.original.data 
          this.interventionToMoForm.productsList = this.inventionProductsList
          })
        .catch(() => {})
        .finally(() => {});
    },
    /* fetchProductsList(){
         fetchProductsList({supplierId: this.$props.supplierId})
        .then((res) => (this.employees = res.data.original.list))
        .catch(() => {})
        .finally(() => {});   
    } */
  },
};
</script>
