<template>
  <div class="col-sm-6 col-md-4 col-xl-3">
    <div class="my-4 text-center">
      <b-modal id="evaluationModal" ref="evaluationModal" size="lg" :title="'Intervention '" title-class="font-18" hide-footer>
        <form >
          <div class="row">
            <div class="form-group col-12">
              <label for="formrow-inputCity">Commentaires *</label>
              <!-- Editor -->
              <ckeditor v-model="evaluationToMoForm.comments" :editor="editor"></ckeditor>
            </div>
          </div>
          <div>
            <button type="button" v-if="$can('approve_mo')" @click="submit('maintenance_supervisor_approved')" class="btn btn-label btn-primary float-right ml-2" >
              <i class="far fa-check-square text-white label-icon"></i> Valide 
            </button>
            <button type="button" v-if="$can('reject_mo')" @click="submit('maintenance_supervisor_rejected')" class="btn btn-label btn-danger float-right ml-2" >
              <i class="far fa-window-close text-white label-icon"></i> Rejeté
            </button>
            <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2">
              <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  fetchProductsList,
} from "@/api/common";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  props: {
      moId: {
          type: Number,
      },
  },
  components: {ckeditor: CKEditor.component},
  data() {
    return {
      show: false,
      products: [],
      evaluationToMoForm: {
        moId: "",
        status: "",
        comments: "",
      },
      editor: ClassicEditor,

      executionResponsibleTypes: [
        {
          id: 'company',
          title: 'SONATRACH',
        },

        {
          id: 'supplier',
          title: 'Prestataire'
        }
      ],
      supId : null,
      inventionProductsList : [],
      fields: [{ id: 1 }],
      phoneData: [{ id: 1 }],
    };
  },
  mounted: function () { 
      // this.fetchProductsList();
    // this.$root.$on('showAssignEmployeesModal', () => { // here you need to use the arrow function
     
    // })
  },
  watch : {
  },
  methods: {
    cancelBtn(){
      this.$refs['evaluationModal'].hide()
    },
    submit(status) {
        this.evaluationToMoForm.status = status;
        this.evaluationToMoForm.moId   = this.$props.moId;
        this.$http
        .post("/ops/maintenance_orders/evaluationToMo", this.evaluationToMoForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success("l'operation a ete effectuée avec succes");
              this.$refs['evaluationModal'].hide()
              this.$emit('realoadSingleMaintenanceOrderData',true)
              break;

            case 500:
              this.$toast.error(res.data.original.msg);
              
              break;
          }
        })
        .catch(() => {
          
        })
        .finally(() => {});
    },
  },
};
</script>
